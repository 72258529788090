import { defineStore } from 'pinia'
import {isAxiosError} from "axios";
import {PublicApi} from '~/gen/openapi/portalService';

interface State {
	errorMessage: string
	isFetching: boolean
	isShowing: boolean
	text: string
	timeLastFetch: number | null
}

export const usePrivacyInformationStore = defineStore('privacyInformation', {
  state: (): State => ({
		errorMessage: '',
		isFetching: false,
		isShowing: false,
		text: '',
		timeLastFetch: null,
	}),
	actions: {
		async fetch() {

			const timeSinceLastFetch = Date.now() - (this.timeLastFetch || 0)
			if (this.text && timeSinceLastFetch < 1000 * 60 * 5) {
				return this.text
			}

			const {$config, $axios} = useNuxtApp()
            const publicApi = new PublicApi(undefined, $config.public.apiBaseHost, $axios)
			try {
				const response = await publicApi.publicGetPrivacyPolicy().then(axiosResponse => axiosResponse.data as any)
				this.text = response.maintext
				this.timeLastFetch = Date.now()
				return response.maintext
			} catch (e: any) {
				if (isAxiosError(e) && e.response) {
					this.errorMessage = `${e.response.status}: ${e.response.statusText}`
				} else {
					this.errorMessage = e.toString()
				}

				throw e
			} finally {
				this.isFetching = false
			}
		},
		hide() {
			this.isShowing = false
		},
		show() {
			this.isShowing = true
		},
		toggle() {
			this.isShowing = !this.isShowing
		}
	}
})
